import {persistStore, persistReducer} from "redux-persist";
import rootReducer from './Components/reducers'
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from 'redux';
import AsyncStorage from '@react-native-community/async-storage';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';

const persistConfig = {
    key: "home",
    storage: AsyncStorage,
    stateReconciler: autoMergeLevel1,
    blackList:['patient_n_key']
};
const pReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(thunk);

const store = createStore(
    pReducer,
    middleware
)

const persistor = persistStore(store);

export { persistor, store };