import { Check, CloseSharp, Delete } from '@material-ui/icons';
import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Alert, Button, Modal, Toast } from 'react-bootstrap';
import {  Circle, DashCircle, PlusCircle, Square, Subtract, TrashFill } from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { APP_BASE_URL } from '../utils/Api';
import { createForms, generateForm, setFormItem } from './reducers/action';


const PreviewForm = (props) => {
    const[elements, setElements] = useState([]);
    const [formName, setFormName] = useState();
    const [disable,setDisable] = useState(true);
    const[image, setImage]=useState(null);
    const [showError, setShowError] = useState(false)
    const[preview, setPreview]=useState(false);
    const [formDescription, setFormDescription]=useState();
    const[show, setShow]=useState(false)
    const {form_data, setItem, save_item,updateItem, onCreateOrder}=props;


  useEffect(()=>{
    setShow(false)
    setArray()

  },[form_data])
useEffect(()=>{
  console.log(elements)
},[elements])
useEffect(async() => {
  setElements([])
  if (performance.navigation.type === 1) {
    await updateItem(null)
    setElements([])
    console.log("This page is reloaded")
  } else {
    console.log("This page is not reloaded")
  }
},[])
const sendFormData=async()=>{
  setPreview(false)
  let data=elements.map((item, index)=>{
    return(
      {
        "question":item.headerlabel,
        "answer":item.options?item.options:null,
        "answer_type":item.name,
        "user_answer":null,
        'placeholder':item.placeholder,
        'key':item.key,
        'min_label':item.min_label?item.min_label:null,
        'max_label':item.max_label?item.max_label:null
        }
    )
  })
  console.log(data)
  const finalData={
  "org_n_key": "ORGID-1",
  "title":formName,
  "description":formDescription,
  "created_by_id": "ORG1-EMP-1",
   "created_by_name": "Careme",
   "hospital_n_key": "CAR-HOS-1",
   "form_data":data
  }
  console.log(finalData)
  var newData = new FormData();
  newData.append("image", image);
  newData.append("data",JSON.stringify(finalData));
  const headers={
    "Content-Type": "application/json",
    "Authorization": "Token 70c48f814c3a6cc1b4e1dcb0ea4b2cc0713c90bf"
   }
  axios.post(`https://getehr.${APP_BASE_URL}/api/orderform_details/`,newData,{headers}).then((res)=>{
     console.log(res)
     if(res.data.status === 'success'){
       setElements([])
       setFormName('')
       setFormDescription('')
       setImage(null)
     }
   })
}
  const setArray=async()=>{
      let data = form_data;
      if(data){
        let dataconcat = [...elements,data.item]
         await setElements(dataconcat)
          setShow(true)
      }
      else{
      setShow(false)
      }
  }

const setForm = (obj,val)=>{
  if(val.trim().length >= 2){
    if(obj === 'form_name'){
      setFormName(val)
    }
    if(obj === 'form_description'){
      setFormDescription(val)
    }
  }
else{
  if(obj === 'form_name'){
    setFormName()
  }
  if(obj === 'form_description'){
    setFormDescription()
  }
  
 
}
}
const deleteIndex=(id)=>{
  let key= id
  let newArr = elements.filter((item, ind)=> ind !==key )
  setElements(newArr)

}
const setHeader=(indx, value)=>{
  setElements(elements.map((item, index)=>{
    if(indx !== index)return item
    return{...item, headerlabel:value}
  }))
}
const setDropPlaceholder=(indx, value)=>{
  setElements(elements.map((item, index)=>{
    if(indx !== index)return item
    return{...item, placeholder:value}
  }))
}
const setPlaceholder=(indx, value)=>{
  setElements(elements.map((item, index)=>{
    if(indx !== index)return item
    return{...item, placeholder:value}
  }))
}
const setRangePlaceholder=(indx, value)=>{
  setElements(elements.map((item, index)=>{
    if(indx !== index)return item
    return{...item, min_label:value}
  }))
}
const setRangeMaxPlaceholder=(indx, value)=>{
  setElements(elements.map((item, index)=>{
    if(indx !== index)return item
    return{...item, max_label:value}
  }))
}
const RemoveItemDrop=(indx)=>{
  var el = elements[indx].options.map((i, ind)=>i)
  el.pop()
  console.log(el)
  setElements(elements.map((item, index)=>{
    if(indx !== index)return item
    return{...item, options:el}
  }))
}
const AddOptionPlaceholder=(inx,indx, value)=>{
  console.log(inx, indx, value)
  var el = elements[inx].options.map((i, ind)=>{
    if(ind !== indx)return i
    return value
  })
  console.log(el)
  setElements(elements.map((item, index)=>{
    if(inx !== index)return item
    return{...item, options:el}
  }))
  
}
const AddOptionPlaceholderdrop=(inx,indx, value)=>{
  console.log(inx, indx, value)
  var el = elements[inx].options.map((i, ind)=>{
    if(ind !== indx)return i
    return {label:value,value:value}
  })
  console.log(el)
  setElements(elements.map((item, index)=>{
    if(inx !== index)return item
    return{...item, options:el}
  }))
  
}
const AddcheckOptionPlaceholder=(inx,indx, value)=>{
  console.log(inx, indx, value)
  var el = elements[inx].options.map((i, ind)=>{
    if(ind !== indx)return i
    return {item:value,check:false}
  })
  setElements(elements.map((item, index)=>{
    if(inx !== index)return item
    return{...item, options:el}
  }))

}
const AddPlaceholder=(indx)=>{
  var el = elements[indx].options.map((i, ind)=>i)
  el.push({label:`Dropdown value ${el.length +1}`,value:`Dropdown value ${el.length +1}`})
  console.log(el)
  setElements(elements.map((item, index)=>{
    if(indx !== index)return item
    return{...item, options:el}
  }))
}
const AddCheckPlaceholder=(indx)=>{
  var el = elements[indx].options.map((i, ind)=>i)
  el.push({item:`Checkbox Value ${el.length +1}`,check:false})
  console.log(el)
  setElements(elements.map((item, index)=>{
    if(indx !== index)return item
    return{...item, options:el}
  }))
}
const AddRadioPlaceholder=(indx)=>{
  var el = elements[indx].options.map((i, ind)=>i)
  el.push(`Radio Value ${el.length+1}`)
  setElements(elements.map((item, index)=>{
    if(indx !== index)return item
    return{...item, options:el}
  }))
}

  return (
    <div className='form_build_preview'>
      {elements && elements.map((i,ind)=>ind===0 && <div className='form_name_container'>
         <div className='inputs_form'> <label >Form Name *:</label><input type="text" placeholder='Form Name (Minimum 2 Characters)' className='form_name_input' onChange={(e)=>setForm('form_name',e.target.value)} /></div>
          <label>Form Description *:</label><textarea type="text" placeholder='Form Description (Minimum 50 Characters)' className='form_description_input' onChange={(e)=>setForm('form_description',e.target.value)}  ></textarea>
          <label>Form Image :</label> <input type="file" className='form_file_input'  onChange={(e)=>{setImage(e.target.files[0])} } style={{display:'inline'}}   />
          {elements.length !==0 && formName && formDescription  && <button className={`save_btn_form ${disable? 'dis_btn':null}`}  onClick={()=>setPreview(true)} >Preview</button>}
        </div>)}
      { show && elements?  elements.map((item, index) =>{
       return(
        <ul>
          <li key={index} >
          <div className='form_inputs'>
          <div className='form_inner_question'>
          <div className='prev_container' >
            <div className='preview_section'>
        <div  ><TrashFill className='delete_input_form'  onClick={()=>deleteIndex(index)} color={'red'} size={18} /></div> 
        <p className='form_question'> {`${index+1})`}{" "}{item?.headerlabel ? item.headerlabel : 'Header' }</p> 
        {item.key === 'EmailID'  &&  <input className='inner_input_form' value={item.placeholder?item.placeholder:'Default Placeholder'}  />}
        {item.key === 'TextInput'  &&  <input className='inner_input_form' value={item.placeholder?item.placeholder:'Default Placeholder'}  />}
        {item.key === 'NumberInput'  &&  <input  className='inner_input_form' value={item.placeholder?item.placeholder:'Default Placeholder'}  />}
        { item.key === 'TextArea' &&  <textarea className='inner_textarea_form' value={item.placeholder?item.placeholder:'Default Placeholder'}></textarea>}
        { item.key === 'Showcase' &&  <textarea className='inner_textarea_form' value={item.placeholder?item.placeholder:'Default Placeholder'}></textarea>}
        {item.key === 'DatePicker' && <><p style={{marginLeft:'15px'}}>DD/MM/YYYY</p></>}
        {item.key === 'Dropdown' &&  <select className='select_form_input' >{item.options.map((el)=><option value={el.value}>{el.value}</option>)}</select>} 
        {item.key === 'Checkboxes' && <>{item.options.map((el, indx)=> <div className='' ><Square />{' '}{el.item}</div>)}</>}
        {item.key === 'Range' && <div className='form_range_input'>{item.min_label}{' '}{item.options.map((el, indx)=> <><button style={{color:"#fff", fontWeight:'bold'}} className={`btn_form_range${indx}`}>{el}</button></>)}{' '}{item.max_label}</div>}
        {item.key === 'RadioButtons' && <div className='radio_input_form'>{item.options.map((el, indx)=> <div className='option_check'><Circle />{' '}{el}</div>)}</div>}
        <input type='text' className={`edit_input_placeholder  ${item.key == 'RadioButtons'||item.key == 'Checkboxes' ? 'up_row':item.key === 'TextArea'?'less_up': item.key === 'Showcase'?'show_up':null}`} placeholder='To Edit Question..' onChange={(e) => {setHeader(index, e.target.value)}} /> 
        {item.key === 'EmailID'  && <input type='text' className='edit_input_value' placeholder='To Edit placeholders..' onChange={(e) => {setPlaceholder(index, e.target.value)}} /> }
          {item.key === 'TextInput'  && <input type='text' className='edit_input_value' placeholder='To Edit placeholders..' onChange={(e) => {setPlaceholder(index, e.target.value)}} /> }
          {item.key === 'NumberInput'  && <input type='text' className='edit_input_value' placeholder='To Edit placeholders..' onChange={(e) => {setPlaceholder(index, e.target.value)}} /> }
          {item.key === 'TextArea'  && <input type='text' className='edit_input_value_textarea' placeholder='To Edit placeholders..' onChange={(e) => {setPlaceholder(index, e.target.value)}} /> }<br/>
          {item.key === 'Showcase'  && <input type='text' className='edit_input_value_textarea' placeholder='To Edit placeholders..' onChange={(e) => {setPlaceholder(index, e.target.value)}} /> }<br/>
          {item.key === 'Range' && <div className='range_scale'>
          <input type='text' className='edit_input_value' placeholder='To Edit min label' onChange={(e) => {setRangePlaceholder(index, e.target.value)}} /> 
          <input type='text' className='edit_input_value_max' placeholder='To Edit max label..' onChange={(e) => {setRangeMaxPlaceholder(index, e.target.value)}} /> 
          </div>}
        {item.key === 'Checkboxes' && <div className='input_dropdown_form_radio'>
        {item.options.map((el, indx)=> <div className='inside_dropdown_input'><input key={indx} onChange={(e)=>AddcheckOptionPlaceholder(index, indx, e.target.value)} type="text" value={el.item} /> </div>)}
        <div className='dropdown_btn_form'>
        <PlusCircle style={{backgroundColor:'green', marginBottom:'5px' ,borderRadius:'10px'}} size={20} color={'#fff'} onClick={()=>{AddCheckPlaceholder(index)}} />
        {item.options[item.options.length - 1] && <DashCircle style={{backgroundColor:'red',borderRadius:'10px'}} size={20} color={'#fff'}  onClick={()=>RemoveItemDrop(index)} />}
       </div> </div>}
        {item.key === 'RadioButtons' && <div className='input_dropdown_form_radio'>
        {item.options.map((el, indx)=> <div className='inside_dropdown_input'><input key={indx} onChange={(e)=>AddOptionPlaceholder(index, indx, e.target.value)} type="text" value={el} /> </div>)}
        <div className='dropdown_btn_form'>
        <PlusCircle style={{backgroundColor:'green', marginBottom:'5px' ,borderRadius:'10px'}} size={20} color={'#fff'} onClick={()=>{AddRadioPlaceholder(index)}} />
        {item.options[item.options.length - 1] && <DashCircle style={{backgroundColor:'red',borderRadius:'10px'}} size={20} color={'#fff'}  onClick={()=>RemoveItemDrop(index)} />}
       </div> </div>}
        {item.key === 'Dropdown' && <div className='input_dropdown_form'>
        {item.options.map((el, indx)=> <div className='inside_dropdown_input'><input className='dropdown_el' key={indx} onChange={(e)=>AddOptionPlaceholderdrop(index, indx, e.target.value)} type="text" value={el.value} /> </div>)}
        <div className='dropdown_btn_form'>
        <PlusCircle className="add_item_form" onClick={()=>{AddPlaceholder(index)}} style={{backgroundColor:'green', marginBottom:'5px' ,borderRadius:'10px'}} size={20} color={'#fff'} /> 
          {item.options[item.options.length - 1] && 
        <DashCircle className="remove_item_form" onClick={()=>RemoveItemDrop(index)} style={{backgroundColor:'red',borderRadius:'10px'}} size={20} color={'#fff'} />}
          </div>  </div>}
        </div>
          </div>
        </div>     
       </div>
       </li>
      </ul> 
      )}) :<div className='build_form'> Start Building your forms</div>}
                  {preview &&
                   <Modal 
                   size="lg"
                   onHide={() => setPreview(false)}
                 s  show={preview} >
                    <Modal.Header closeButton > 
                   <Modal.Title> <p  style={{marginLeft:'350px', textDecoration:'underline'}}>{formName}</p>
                 <p style={{fontSize:'18px'}}> {formDescription}</p>
                  <div> {image && <img width='50px' height='50px' src={URL.createObjectURL(image)} />}</div>
                 </Modal.Title></Modal.Header>
                 <Modal.Body>  {elements && elements.map((item, index)=>{
                      return(
                        <div className='inline_preview'>
                        <p>{`${index+1})`}{' '}{item.headerlabel}</p>
                        {item.key === 'TextInput' && <input className='answer_input_inline_prev' value={item.placeholder} />}
                        {item.key === 'NumberInput' && <input  className='answer_input_inline_prev' value={item.placeholder} />}
                        {item.key === 'EmailID' && <input className='input_inline_prev' value={item.placeholder} />}
                        {item.key === 'TextArea' && <textarea className='input_inline_prev' value={item.placeholder}></textarea>}
                        {item.key === 'Showcase' && <textarea className='input_inline_prev' value={item.placeholder}></textarea>}
                        {item.key === 'DatePicker' && <div className='date_inline_prev'><p>DD/MM/YYYY</p></div>}
                        {item.key === 'RadioButtons' && <div className='inline_radio' >{item.options.map((el, indx)=> <p className='radio_inline_prev'><Circle />{' '}{el}</p>)}</div>}
                        {item.key === 'Checkboxes' && <div className='inline_radio'>{item.options.map((el, indx)=> <p className='radio_inline_prev'><Square />{' '}{el.item}</p>)}</div>}
                        {item.key === 'Dropdown' && <select className='input_inline_prev' >{item.options.map((el)=><option value={el.value}>{el.value}</option>)}</select>}
                        {item.key === 'Range' && <div className='form_range_input'>{item.min_label}{' '}{item.options.map((el, indx)=> <><button style={{color:"#fff", fontWeight:'bold'}} className={`btn_form_range${indx}`}>{el}</button></>)}{' '}{item.max_label}</div>}
                        </div>
                      )
                    
                    })}
                    </Modal.Body>   
                    <Modal.Footer>
                      <Button onClick={()=>sendFormData()}>Submit</Button>
                    </Modal.Footer>
                  </Modal>}
                 
    </div>
  )
}


const mapDispatchToProps = (dispatch) => ({
  setItem:(data)=>dispatch(setFormItem(data)),
  updateItem:(data)=>dispatch(generateForm(data)),
  onCreateOrder:(data)=>dispatch(createForms(data)),
});

const mapStateToProps = (state) => ({
  form_data:state.home.form_data,
  save_item:state.home.save_item,
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewForm);
