import React, { useEffect, useState } from "react";
import PreviewForm from "./PreviewForm";
import Toolbar from "./Toolbar";
import { connect } from "react-redux";
import EditForm from "./EditForm";
import { useHistory, useLocation } from "react-router-dom";
import Loadersvg from '../assets/images/loader.svg';

const Loader = () => (
  <div className="splash">
    <div className="color-line"></div>
    <div className="splash-title">
      <h1>Loading Form Builder....</h1>
      <img src={Loadersvg} width="128" height="128" />
    </div>
  </div>
);
const CreateForms = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader]=useState(true);
  useEffect(() => {
    location.state?.data?.id ? setLoader(false) : history.push('/error');
  }, []);

  return (

    loader ? <Loader /> :
    <div className="form_builder">
      <div>
        <Toolbar />
      </div>
      <div>
        <PreviewForm />
      </div>
      {/* <div className='col-md-12 col-sm-12  col-xl-3'>
          <EditForm />      
          </div> */}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({
  patient_n_key: state.home.patient_n_key,
  appointment_n_key: state.home.appointment_n_key,
  orderId: state.home.orderId,
  app_payment_n_key: state.home.app_payment_n_key,
  product_key: state.home.product_key,
  price_details: state.home.price_details,
  uuid_token: state.home.uuid_token,
  mode: state.home.mode,
  problem: state.home.problem,
  gender: state.home.gender,
  date: state.home.date,
  language: state.home.language,
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateForms);
