import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Loadersvg from '../assets/images/loader.svg';
import { useHistory, useLocation } from "react-router-dom";
import {
  ArrowDown,
  Circle,
  DashCircle,
  PlusCircle,
  Square,
  Subtract,
  TrashFill,
} from "react-bootstrap-icons";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { slideInUp } from "react-animations";
import { slideInDown } from "react-animations";
import Radium, { StyleRoot } from "radium";
import axios from "axios";
import { ArrowForward, ExpandLess, ExpandMore } from "@material-ui/icons";
import './Answer.css'
import { APP_BASE_URL } from "../utils/Api";

const styles = {
  slideInUp: {
    animation: "x 1s",
    animationName: Radium.keyframes(slideInUp, "slideInUp"),
  },
  slideInDown: {
    animation: "x 1s",
    animationName: Radium.keyframes(slideInDown, "slideInDown"),
  },
};

// const data = [
//   {
//     answer: null,
//     answer_type: "Text Input",
//     key: "TextInput",
//     placeholder: "Enter Your Text",
//     user_answer: null,
//     question: "Question 1",
//   },
//   {
//     answer: null,
//     answer_type: "Email",
//     key: "EmailID",
//     user_answer: null,
//     placeholder: "Enter Your Email",
//     question: "Question 2",
//   },
//   {
//     answer: ["Dropdown Value 1", "Dropdown value 2", "Dropdown value 3"],
//     answer_type: "Dropdown",
//     key: "Dropdown",
//     placeholder: "Enter Your Text",
//     question: "Question 3",
//     user_answer: null,
//   },
//   {
//     answer: [
//       { item: "Checkbox Value 1", check: false },
//       { item: "Checkbox value 2", check: false },
//       { item: "Checkbox value 3", check: false },
//     ],
//     answer_type: "Checkboxes",
//     key: "Checkboxes",
//     placeholder: "Enter Your Text",
//     question: "Question 4",
//     user_answer: null,
//   },
//   {
//     answer: [
//       "Radio Value 1",
//       "Radio value 2",
//       "Radio value 3",
//       "Radio value 4",
//       "Radio value 5",
//     ],
//     answer_type: "Multiple Choice",
//     key: "RadioButtons",
//     placeholder: "Enter Your Text",
//     question: "Question 5",
//     user_answer: null,
//   },
//   {
//     answer: null,
//     answer_type: "Date",
//     key: "DatePicker",
//     placeholder: "Enter Your Text",
//     question: "Question 6",
//     user_answer: null,
//   },
//   {
//     answer: null,
//     answer_type: "Multi-line Input",
//     key: "TextArea",
//     placeholder: "Enter Your Text",
//     question: "Questionv 7",
//     user_answer: null,
//   },
//   {
//     answer: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
//     answer_type: "Scale Input",
//     key: "Range",
//     min_label: "Min",
//     max_label: "Max",
//     placeholder: "Enter Your Text",
//     question: "Question 8",
//     user_answer: null,
//   },
// ];
const formName = "Anxiety";
const Description =
  "This questionnaire called the GAD-7 screening tool can help you find out if you might have an anxiety disorder that needs treatment. It calculates how many common symptoms you have and based on your answers suggests where you might be on a scale, from mild to severe anxiety";


const Loader = () => (
  <div className="splash">
    <div className="color-line"></div>
    <div className="splash-title">
      <h1>Loading Form....</h1>
      <img src={Loadersvg} width="128" height="128" />
    </div>
  </div>
);

const Success = () => (
  <div className="box"> 
  <div className="success alerts">
    <div className="alerts-body">
      Success !
    </div>
  </div>
</div>
)

const Answeruser = (props) => {
  const [step, setStep] = useState(0);
  const [slide, setSlide] = useState(false);
  const [show, setShow] = useState(false);
  const [indx, setIndx] = useState(0);
  const [value, setValue] = useState([]);
  const [date, setDate] = useState(new Date());
  const [success, setSuccess]=useState(false);
  const [formDetails, setFormDetails] = useState([]);
  const [patientDetails, setPatientDetails] = useState([]);
  const [valEmail, setValEmail] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [loader, setLoader]=useState(true);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
  };
  const WelcomeScreen = (props) => {
    return (
      <div className="welcome_form">
        <div className="form_wel_title">Welcome to {formDetails.title} Test</div>
        <div className="form_wel_description">{formDetails.description}</div>
        <button className="wel_btn" onClick={() => props.onCLick()}>
          Begin Test
        </button>
      </div>
    );
  };
  const fetchData = async () => {
    const patientData = { appointment_n_key: location.state?.data?.id};
    const res = await axios.post(
      `https://getehr.${APP_BASE_URL}/api/appoint_patient/`,
      patientData,
      { headers }
    );
    setPatientDetails(res.data.data);
    console.log(res);
  };
  const fetchFormData = async () => {
    const res = await axios.get(
      `https://getehr.${APP_BASE_URL}/api/orderform_details/${location.state?.data?.formId}/`, {},
      { headers }
    );
    console.log(res);
    setFormDetails(res?.data);
    setValue(res.data?.form_data)
  };

  useEffect(() => {
    fetchData();
    fetchFormData();
    location.state?.data?.id && formDetails && patientDetails ? setLoader(false) : history.push('/error');
  }, []);


  useEffect(() => {
    setShow(true);
  }, [show]);
  const onCLick = () => {
    setStep(step + 1);
  };
  const sendForm = () => {
    const data = {
      link_id: 3,
      patient_n_key: patientDetails.patient_n_key,
      form: formDetails.title,
      order_form_id: formDetails.order_form_id,
      values: value,
    };
    
    axios
      .post(
        `https://getehr.${APP_BASE_URL}/api/caremeapp/therpayform_post/`,
        data,
        { headers }
      )
      .then((res) => {
        console.log(res);
        if (res.status == 200){
          setSuccess(true);
          setLoader(false);
        }
      });
  };
  useEffect(() => {
    console.log(value);
  }, []);
  const handleChange = (id, val) => {
    console.log(id, val);
    setValue(
      value.map((item, index) => {
        if (id !== index) return item;
        return { ...item, user_answer: val };
      })
    );
    setSlide(true);
  };
  const emailChange = (id, val) => {
    console.log(id, val);
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regEmail.test(val)) {
      setValEmail(false);
      setValue(
        value.map((item, index) => {
          if (id !== index) return item;
          return { ...item, user_answer: val };
        })
      );
    } else {
      setValEmail(true);
    }
  };
  const handleDrop = (indx, vals) => {
    setValue(
      value.map((item, index) => {
        if (indx !== index) return item;
        return { ...item, user_answer: vals };
      })
    );
  };
  const handleCheck = (inddx, id, val, els) => {
    setValue(
      value.map((item, index) => {
        var arr = [value[indx].user_answer];
        if (val) {
          if (arr[0] == null) {
            let newArr = [];
            newArr.push(els);
            arr = newArr;
            value[indx].answer[inddx].check = true;
          } else arr.push(els);
          value[indx].answer[inddx].check = true;
        }
        if (!val) {
          var newA = [].concat.apply([], arr).filter((i) => i !== els);
          arr = newA;
          value[indx].answer[inddx].check = false;
        }
        if (id !== index) return item;
        return { ...item, user_answer: [].concat.apply([], arr) };
      })
    );
    console.log(value);
  };
  const sendFront = () => {
    setSlide(true);
    setShow(false);
  };
  const sendBack = () => {
    setSlide(false);
    setShow(false);
  };
  return (
    loader ? <Loader /> : !loader && success ? <Success /> :
    <div className="answer_section_form">
      {step == 0 && <WelcomeScreen onCLick={onCLick} />}
      {step == 1 && (
        <div className="typeform_Progress">
          <p className="typeform_count_bar">
            <span>
              Question {indx + 1} / {value.length}
            </span>
          </p>
          <div
            className="typeform_progress_bar_"
            style={{ width: `${((indx + 1) / value.length) * 100 + "%"}` }}
          ></div>
        </div>
      )}
      {step == 1 && show && (
        <StyleRoot>
          {" "}
          <div style={slide ? styles.slideInUp : styles.slideInDown}>
            <div className="answer_types_form">
              <p className="ques_inx">
                {" "}
                {indx + 1}
                <ArrowForward /> {value[indx].question}
              </p>
            </div>
            <div>
              {value[indx].key === "TextInput" && (
                <input
                  className="input_inline__ans"
                  onChange={(e) => handleChange(indx, e.target.value)}
                  defaultValue={value[indx].user_answer}
                  placeholder={
                    value[indx].user_answer
                      ? value[indx].user_answer
                      : value[indx].placeholder
                  }
                />
              )}
                 {value[indx].key === "NumberInput" && (
                <input
                  type="number"
                  className="input_inline__ans"
                  onChange={(e) => handleChange(indx, e.target.value)}
                  defaultValue={value[indx].user_answer}
                  placeholder={
                    value[indx].user_answer
                      ? value[indx].user_answer
                      : value[indx].placeholder
                  }
                />
              )}
              {value[indx].key=== "Showcase" && (
               <div
               className="show_case"
               >{value[indx].placeholder}</div>
              )}
              {value[indx].key === "EmailID" && (
                <input
                  className="input_inline__ans"
                  defaultValue={value[indx].user_answer}
                  onChange={(e) => emailChange(indx, e.target.value)}
                  placeholder={
                    value[indx].user_answer
                      ? value[indx].user_answer
                      : value[indx].placeholder
                  }
                />
              )}
              {value[indx].key === "EmailID" && valEmail && (
                <span className="err_msg_">Enter a valid email</span>
              )}
              {value[indx].key === "Dropdown" && (
                <select
                  defaultValue={
                    value[indx].user_answer ? value[indx].user_answer : ""
                  }
                  onChange={(e) => handleChange(indx, e.target.value)}
                  className="input_inline__drop"
                >
                  {value[indx].answer.map((el) => (
                    <option value={el.value}>{el.value}</option>
                  ))}
                </select>
              )}
              {value[indx].key === "Checkboxes" && (
                <div className="inline_radio__ans">
                  <FormGroup>
                    {value[indx].answer.map((el, inx) => {
                      return (
                        <div
                          style={{ width: "40vh" }}
                          className="check_box_ans"
                        >
                          <FormControlLabel
                            key={inx}
                            control={
                              <Checkbox
                                checked={el.check}
                                inputProps={{ "aria-label": "controlled" }}
                                onChange={(e) => {
                                  handleCheck(
                                    inx,
                                    indx,
                                    e.target.checked,
                                    el.item
                                  );
                                }}
                              />
                            }
                            label={el.item}
                          ></FormControlLabel>
                        </div>
                      );
                    })}
                  </FormGroup>
                </div>
              )}
              {value[indx].key === "Checkboxes" && (
                <span className="check_span">(Select all that applies) </span>
              )}

              {value[indx].key === "RadioButtons" && (
                <div className="inline_radio__ans">
                  <FormGroup>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      // value={value}
                      defaultValue={value[indx].user_answer}
                      onChange={(e) => handleDrop(indx, e.target.value)}
                    >
                      {value[indx].answer.map((el, inx) => {
                        return (
                          <div
                            style={{ width: "35vh" }}
                            className="check_box_ans"
                          >
                            <FormControlLabel
                              key={inx}
                              value={el}
                              control={<Radio />}
                              label={el}
                            ></FormControlLabel>
                          </div>
                        );
                      })}
                    </RadioGroup>
                  </FormGroup>
                </div>
              )}
              {value[indx].key === "DatePicker" && (
                <div className="date_inline__ans">
                  <ReactDatePicker
                    className="date_pic_ans"
                    onChange={(dat) => {
                      setDate(dat);
                      handleChange(indx, dat);
                    }}
                    selected={date}
                    dateFormat="dd/MM/yyyy"
                    showYearDropdown
                  />
                </div>
              )}
              {value[indx].key === "Range" && (
                <div className="form_range__ans">
                  <div className="btn_grp_ans">
                    {value[indx].min_label}{" "}
                    {value[indx].answer.map((el, inx) => (
                      <>
                        <button
                          style={{ color: "#fff", fontWeight: "bold" }}
                          onClick={() => handleChange(indx, el)}
                          className={`btnform_range${inx} ${
                            value[indx].user_answer === el ? "active_btn" : null
                          }`}
                        >
                          {el}
                        </button>
                      </>
                    ))}{" "}
                    {value[indx].max_label}
                  </div>
                </div>
              )}
              {value[indx].key === "TextArea" && (
                <textarea
                  className="input_inline_txt__ans"
                  defaultValue={value[indx].user_answer}
                  onChange={(e) => handleChange(indx, e.target.value)}
                  placeholder={
                    value[indx].user_answer
                      ? value[indx].user_answer
                      : value[indx].placeholder
                  }
                ></textarea>
              )}
            </div>
          </div>
        </StyleRoot>
      )}

      {step == 1 && (
        <div className="next_div">
          {value.length === indx + 1 && (
            <button
              onClick={() => {
                sendForm();
              }}
              className="submit_ans_"
            >
              Submit
            </button>
          )}
          {indx > 0 && (
            <ExpandLess
              onClick={() => {
                setIndx(indx - 1);
                sendBack();
              }}
              className="exp_less"
            />
          )}
          {value.length === indx + 1 ? null : (
            <ExpandMore
              onClick={() => {
                setIndx(indx + 1);
                sendFront();
              }}
              className="exp_less"
            />
          )}
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Answeruser);
