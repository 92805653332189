import axios from "axios";

export const BASE_URL = process.env.NODE_ENV === "development" ? 'https://getehr.synapstics.com/api/caremeapp':process.env.REACT_APP_BASE_URL

export const APP_BASE_URL="synapstics.com"; 

export const headers= {
    "Content-Type": "application/json",
    "Authorization": "a7143772a09f993125901ea752bfd4fcf4b31d29"
   }

export const PostApi = async(data)=>{
    console.log(data)
    let url = data.url
    let params=data.values
    var val = ''
    await axios.post(BASE_URL+url,params,{headers}).then((res)=>{
        console.log(res.data)
       if(res){
        val= res.data
       }
       else{val= "error"}
    })
    .catch((err)=>{
        console.log(err)
    })
    return val
}

export const GetApi=async(data)=>{
    console.log(data)
    let url = data.url
    var val = ''
   await axios.get(BASE_URL+url,{headers}).then((res)=>{
      console.log(res.data)
       if(res){
        val= res.data
       }
       else{val= "error"}
    })
    .catch((err)=>{
        console.log(err)
    })
    return val
}

export const getProdKey = ()=>{
    var data = {}
    // if(process.env.NODE_ENV ==='production'){
       data.Relationship = "APP_PROD-2";
       data.Stress = "APP_PROD-7";
       data.Depression="APP_PROD-6";
       data.Chat ="APP_PROD-4";
       data.Initial ="APP_PROD-1";
       data.Psychiatrist="APP_PROD-3"
    //  }
    //  else{
    //     data.Relationship = "APP_PROD-2";
    //     data.Stress = "APP_PROD-8";
    //     data.Depression="APP_PROD-5";
    //     data.Chat ="APP_PROD-7";
    //     data.Initial ="APP_PROD-1";
    //    data.Psychiatrist="APP_PROD-6"

    // }

    return data
}

const languageCode = {
    'english': 'en',
    'tamil': 'ta'
  }
  
  export const getLangCode = (lang) => {
    return languageCode[lang];
  }
