import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import Loader from '../assets/images/loader.svg';
const Validator = () => {
  const { appId, formId, uuid } = useParams();
  const history = useHistory();

  useEffect(() => {
    console.log(uuid);
    uuid == 'therapist' ? history.push("/answer", { data: { id: appId, formId:  formId} }) : uuid == 'patient' ? history.push("/user/answer", { data: { id: appId, formId:  formId} }) : uuid == 'ajarnura' ? history.push("/", { data: { id: appId, formId:  formId} }): uuid == 'editanswer' ? history.push("/editanswer", { data: { id: appId, formId:  formId} }) : history.push("/error");
  }, []);
  return (
    <div>
      <div className="splash">
        <div className="color-line"></div>
        <div className="splash-title">
          <h1>Loading Form Builder....</h1>
          <img src={Loader} width="128" height="128" />
        </div>
      </div>
    </div>
  );
};

export default Validator;
