import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { generateForm } from './reducers/action';
import {  UiChecksGrid,UiRadios, MenuButton, EnvelopeFill, Calendar2, CardText, TextareaT, TextareaResize, Rulers, SortNumericDownAlt, SortNumericUpAlt } from 'react-bootstrap-icons';
import { v4 as uuidv4 } from 'uuid';
import { library } from "@fortawesome/fontawesome-svg-core";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const data = [
  {
    key: 'TextInput',
    canHaveAnswer: true,
    name: 'Text Input',
    label: 'Enter Your Text header',
    icon: 'TextareaT',
    field_name: 'text_input_',
    headerlabel:'Question',
     placeholder:'Enter Your Text',
  },
  {
    key: 'NumberInput',
    canHaveAnswer: true,
    name: 'Number Input',
    label: 'Enter Your Text header',
    icon: 'TextareaT',
    field_name: 'number_input_',
    headerlabel:'Question',
     placeholder:'Enter Your Text',
  },
  {
    key: 'EmailID',
    canHaveAnswer: true,
    name: 'Email',
    label: 'Email label',
    icon: 'fas fa-envelope',
    field_name: 'email_text_input_',
    headerlabel:'Question',
    placeholder:'Enter Your Email',

  },
  {
    key: 'Showcase',
    canHaveAnswer: true,
    name: 'Showcase',
    label: 'Showcase label',
    icon: 'fas fa-envelope',
    field_name: 'Showcase_label',
    headerlabel:'Question',
    placeholder:'Some Text to Welcome/Thanking..',

  },
  {
    key: 'Dropdown',
    canHaveAnswer: true,
    name: 'Dropdown',
    icon: 'far fa-caret-square-down',
    label: 'Dropdown Header',
    field_name: 'dropdown_',
    options: [
      {label:'Dropdown Value 1',value:'Dropdown Value 1'},
      {label:'Dropdown Value 2',value:'Dropdown Value 2'}, 
      {label:'Dropdown Value 3',value:'Dropdown Value 3'},

      ],
    headerlabel:'Question',
    placeholder:'Enter Your Text',

  },
  {
    key: 'Checkboxes',
    canHaveAnswer: true,
    name: 'Checkboxes',
    icon: 'far fa-check-square',
    label: ' Checkbox Header',
    field_name: 'checkboxes_',
    options: [
      {item:'Checkbox Value 1',check:false},
      {item:'Checkbox Value 2',check:false},
      {item:'Checkbox Value 3',check:false},
    ],
    headerlabel:'Question',
    placeholder:'Enter Your Text',

  },
  {
    key: 'RadioButtons',
    canHaveAnswer: true,
    name: 'Single Choice',
    icon: 'far fa-dot-circle',
    label: 'Multichoice header',
    field_name: 'radiobuttons_',
    options: [
      'Radio Value 1',
      'Radio value 2',
      'Radio value 3'
    ],
    headerlabel:'Question',
    placeholder:'Enter Your Text',

  },
  {
    key: 'DatePicker',
    canDefaultToday: true,
    canReadOnly: true,
    dateFormat: 'MM/dd/yyyy',
    timeFormat: 'hh:mm aa',
    showTimeSelect: false,
    showTimeSelectOnly: false,
    name: 'Date',
    icon: 'Calendar2',
    label: 'Date Header',
    field_name: 'date_picker_',
    headerlabel:'Question',
    placeholder:'Enter Your Text',

  },
  {
    key: 'TextArea',
    canHaveAnswer: true,
    name: 'Multi-line Input',
    label: 'Textarea Header',
    icon: 'TextareaResize',
    field_name: 'text_area_',
    headerlabel:'Question',
    placeholder:'Enter Your Text',

  },
  {
    key: 'Range',
    name: 'Scale Input',
    icon: 'fas fa-sliders-h',
    label: 'Slider Header',
    field_name: 'range_',
    step: 1,
    default_value: 3,
    options:[
      '1','2','3','4','5','6','7','8','9','10'
    ],
    min_label: 'Easy',
    max_label: 'Difficult',
    headerlabel:'Question',
    placeholder:'Enter Your Text',

  },
  // {
  //   key: 'Camera',
  //   name: 'File Upload',
  //   icon: 'fas fa-paperclip',
  //   label: 'File Name',
  //   field_name: 'camera_',
  //   headerlabel:'Question',
  //   placeholder:'Enter Your Text',

  // },
];
const Toolbar = (props) => {
  const{onCreateForm}=props;
   const[items, setItems] = useState(data);


const createForm =async(data)=>{
     await onCreateForm(data)
}

  return (
    <div className='form_toolbar'>
        <p className='select_field'>Select the Field's</p> 
           <ul>
            {
            items.map((item, index) => (<>
            <div onClick={()=>{createForm({'id':uuidv4(),'item':item})}} className='form_item' key={index} >
               {item.name}{" "}<p className='icons_toolbar'> {item.key==='TextInput'?<TextareaT /> :item.key==='NumberInput'?<SortNumericUpAlt /> :item.key==='EmailID'?<EnvelopeFill /> :item.key==='Dropdown'? <MenuButton /> :item.key==='Checkboxes'? <UiChecksGrid /> :item.key==='RadioButtons'? <UiRadios /> :item.key==='DatePicker'?<Calendar2 /> :item.key==='TextArea'? <TextareaResize /> :item.key==='Showcase'? <CardText /> :item.key==='Range'?<Rulers /> :null}</p>
            </div>
            </>))
              
            }
          </ul>
    </div>
  )
}
const mapDispatchToProps = (dispatch) => ({
  onCreateForm:(data)=>dispatch(generateForm(data)),
});

const mapStateToProps = (state) => ({
  patient_n_key: state.home.patient_n_key,
  appointment_n_key: state.home.appointment_n_key,
  orderId: state.home.orderId,
  app_payment_n_key: state.home.app_payment_n_key,
  product_key: state.home.product_key,
  price_details: state.home.price_details,
  uuid_token:state.home.uuid_token,
  mode:state.home.mode,
  problem:state.home.problem,
  gender:state.home.gender,
  date:state.home.date,
  language:state.home.language
});
export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);
