
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createStore} from 'redux';
import {Provider} from 'react-redux'
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import reducer from './Components/reducers/reducer'
import 'bootstrap/dist/css/bootstrap.css'; 
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import i18n from './i18n'
import { I18nextProvider } from 'react-i18next';


ReactDOM.render(
  <React.StrictMode>
   <Provider store={store}> 
   <PersistGate loading={null} persistor={persistor}>
     <I18nextProvider i18n={i18n}>
   <App />
   </I18nextProvider>
   </PersistGate>
   </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== "development"){
  console.log = console.warn = console.error = () => {};
}
reportWebVitals();

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
