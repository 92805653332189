import {
  COUNTRY_CHANGE,
  MASTER_DATA,
  APP_DATA,
  PRODUCT_KEY,
  UUID_TOKEN,
  ORDER_ID,
  APP_KEY,
  PRICE_DETAILS,
  APP_N_Key,
  DOC_NAME,
  MODE,
  LANGUAGE,
  GENDER,
  DATE,
  PROBLEM,
  TIME,
  FORM,
  FORM_ITEM,
  SAVE_ITEM,
} from "./constant";
// import { GetLocationID } from "../../utils/GetLocation";

// var CounttCode = GetLocationID();
// switch (CounttCode) {
//   case "IN":
//     CounttCode = "INR";
//   case "AE":
//     CounttCode = "AED";
//   case "AU":
//     CounttCode = "AUD";
//   case "BD":
//     CounttCode = "BDT";
//   case "CA":
//     CounttCode = "CAD";
//   case "GB":
//     CounttCode = "GBP";
//   case "ID":
//     CounttCode = "ID";
//   case "MY":
//     CounttCode = "MYR";
//   case "NZ":
//     CounttCode = "NZD";
//   case "PH":
//     CounttCode = "PH";
//   case "QA":
//     CounttCode = "QAR";
//   case "SA":
//     CounttCode = "SAR";
//   case "SG":
//     CounttCode = "SGD";
//   case "US":
//     CounttCode = "USD";
//   case "VN":
//     CounttCode = "VN";
//   default:
//     CounttCode = "INR";
// }

const InitialState = {
  // currencyCode: CounttCode,
  patient_n_key: null,
  appointment_n_key: null,
  app_status_n_key: null,
  orderId: null,
  app_payment_n_key: null,
  product_key: {},
  price_details: {},
  uuid_token: null,
  problem: null,
  language: null,
  date: null,
  appoint_time: null,
  mode: null,
  gender: null,
  doc_name: null,
  form_data:null,
  form_item:null,
  save_item:null,
};

const reducer = (state = InitialState, action) => {
  console.log(action.payload);
  switch (action.type) {
    case COUNTRY_CHANGE:
      return {
        ...state,
        currencyCode: action.val,
      };
    case MASTER_DATA:
      return {
        ...state,
        patient_n_key: action.payload.patient_n_key,
        appointment_n_key: action.payload.appointment_n_key,
      };
    case APP_DATA:
      return {
        ...state,
        app_status_n_key: action.payload.app_status_n_key,
      };
    case ORDER_ID:
      return {
        ...state,
        orderId: action.payload.orderId,
      };
    case APP_KEY:
      return {
        ...state,
        app_payment_n_key: action.payload.app_payment_n_key,
      };
    case PRODUCT_KEY:
      return {
        ...state,
        product_key: action.payload,
      };
    case PRICE_DETAILS:
      return {
        ...state,
        price_details: action.payload,
      };
    case UUID_TOKEN:
      return {
        ...state,
        uuid_token: action.payload,
      };
    case MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
      case PROBLEM:
        return {
          ...state,
          problem: action.payload,
        };
    case GENDER:
      return {
        ...state,
        gender: action.payload,
      };
    case TIME:
      return {
        ...state,
        appoint_time: action.payload,
      };
    case DATE:
      return {
        ...state,
        date: action.payload,
      };
    case APP_N_Key:
      return {
        ...state,
        appointment_n_key: action.payload,
      };
    case DOC_NAME:
      return {
        ...state,
        doc_name: action.payload,
      };
      case FORM:
      return {
        ...state,
        form_data: action.payload,
      };
      case FORM_ITEM:
        return{
          ...state,
          form_item:action.payload
        };
        case SAVE_ITEM:
          return{
            ...state,
            save_item:action.payload
          };
    default:
      return state;
  }
};

export default reducer;
