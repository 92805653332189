import React,{useEffect, useState} from 'react'
import { connect } from 'react-redux';
import { saveFormItem } from './reducers/action';

const EditForm = (props) => {
  const [value, setValue]=useState();
  const [data, setData] = useState();
  const {form_item, saveItem, form_data}=props;

  useEffect(()=>{
    if(form_item){
      setData(form_item)
      console.log(form_item)
    }
  },[form_item])

  const getInput = (key,val) => {
 
    setValue({
      ...value,
      [key]: val,
    });
  };
  const setSaveItem=async()=>{
   await saveItem({data, value})
   setData(null)
  }
  return (
    <div className='edit_form'>
       {data && data.item.key === 'TextInput' && <div>
         <form>
        <div>Header: <input id='headerlabel' name='headerlabel' onChange={(e)=>{getInput('headerlabel',e.target.value)}}  type='texr' /> </div>
        <div>PlaceHolder: <input id='placeholder' onChange={(e)=>{getInput('placeholder',e.target.value)}} name='placeholder'  type='text'  /> </div>
        </form>
        <button onClick={()=>setSaveItem()}>Save</button>
       </div>}
       {data && data.item.key === 'EmailID' && <div>
        <div>Header: <input name='headerlabel' onChange={(e)=>{getInput('headerlabel',e.target.value)}} /> </div>
        <div>PlaceHolder: <input onChange={(e)=>{getInput('placeholder',e.target.value)}} name='placeholder' /> </div>
        <button onClick={()=>setSaveItem()}>Save</button>
       </div>}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  saveItem:(data)=>dispatch(saveFormItem(data)),
});

const mapStateToProps = (state) => ({
  form_item:state.home.form_item,
  form_data:state.home.form_data,
  
});

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);