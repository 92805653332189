import React, { useEffect, useState } from "react";
import './assets/css/change.css'
import { Switch ,Route} from 'react-router-dom'
import { BrowserRouter } from "react-router-dom";
import CreateForms from "./Components/CreateForms";
import Validator from "./Components/Validator";
import Answer from "./Container/Answer";
import Answeruser from "./Container/Answeruser";
import Error from "./Components/Error/Error";
import AnswerEdit from "./Container/AnswerEdit";
import { APP_BASE_URL } from "./utils/Api";



const App = (props) => {

  const [pageName, setPageName] = useState('')
  useEffect(() => {
    setTimeout(setPageName(window.location.pathname), 2000);
  }, [])
  const goBack = () => {
    const URL = (pageName === '/answer' || pageName === '/editanswer') ? `https://getehr.${APP_BASE_URL}/profile` : ( pageName == '/' ) ? `https://${APP_BASE_URL}/settings` : null;
    window.location = URL
  }
  
  return (
    <BrowserRouter>
      {(pageName == '/answer' || pageName == '/' || pageName == '/editanswer')?
      <div className="col-md-12" style={{marginTop: '20px'}} >
        <div className="col-md-6 right-border-line" >
          <a  href="#" onClick={() => goBack()} className="ls-p"><span className="rep-back">←</span>  back</a>
        </div>
      </div> : null}
      <Switch>
        <Route exact path="/" component={CreateForms}/>
        <Route path='/answer' component={Answer} />
        <Route path='/editanswer' component={AnswerEdit} />
        <Route path='/user/answer' component={Answeruser} />
        <Route path='/validator/:appId/:formId/:uuid' component={Validator} />
        <Route path='/error' component={Error} />
      </Switch>
    </BrowserRouter>
  );
};

export default App;

