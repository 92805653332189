export const COUNTRY_CHANGE = 'COUNTRY_CHANGE';
export const SEND_OTP = "SEND_OTP";
export const USER_UPDATE = "USER_UPDATE";
export const MASTER_DATA = "MASTER_DATA";
export const APP_DATA = "APP_DATA";
export const ORDER_ID="ORDER_ID";
export const APP_KEY="APP_KEY";
export const PRODUCT_KEY="PRODUCT_KEY";
export const PRICE_DETAILS="PRICE_DETAILS";
export const UUID_TOKEN="UUID_TOKEN";
export const LANGUAGE="LANGUAGE";
export const PROBLEM="PROBLEM";
export const MODE="MODE";
export const GENDER="GENDER";
export const DATE="DATE";
export const TIME="TIME";
export const APP_N_Key="APP_N_Key";
export const DOC_NAME="DOC_NAME";
export const FORM="FORM";
export const FORM_ITEM="FORM_ITEM"; 
export const SAVE_ITEM="SAVE_ITEM"; 