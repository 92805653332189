import { COUNTRY_CHANGE, MASTER_DATA, SEND_OTP,APP_N_Key,DOC_NAME,FORM,PRODUCT_KEY,PRICE_DETAILS,UUID_TOKEN, APP_DATA, ORDER_ID, APP_KEY, MODE, LANGUAGE, DATE, TIME, PROBLEM, GENDER, FORM_ITEM, SAVE_ITEM,} from "./constant";
import { PostApi, getProdKey, GetApi } from "../../utils/Api";

export const onCountryChange = payload => {
    return{
        type:COUNTRY_CHANGE,
        payload
    }
}
export const getNkey = payload=>{
  return{
      type:APP_N_Key,
      payload
  }
}
export const getdoc = payload=>{
  return{
      type:DOC_NAME,
      payload
  }
}
export const getProduct = payload=>{
    return{
        type:PRODUCT_KEY,
        payload
    }
}
export const setPriceDetails = payload=>{
    return{
        type:PRICE_DETAILS,
        payload
    }
}
export const getPatientKey = payload =>{
    return{
        type:MASTER_DATA,
        payload
    }
}
export const getAppdata = payload =>{
    return{
        type:APP_DATA,
        payload
    }
}
export const getOrderId = payload=>{
    return{
        type:ORDER_ID,
        payload 
    }
}
export const getPaymentKey = payload=>{
    return{
        type:APP_KEY,
        payload 
    }
}
export const getUid = payload=>{
  return{
    type:UUID_TOKEN,
    payload
  }
}
export const getmode=payload=>{
  return{
    type:MODE,
    payload
  }
}
export const getlanguage=payload=>{
  return{
    type:LANGUAGE,
    payload
  }
}
export const getDate=payload=>{
  return{
    type:DATE,
    payload
  }
}
export const getTime=payload=>{
  return{
    type:TIME,
    payload
  }
}
export const getProblem=payload=>{
  return{
    type:PROBLEM,
    payload
  }
}
export const getGender=payload=>{
  return{
    type:GENDER,
    payload
  }
}
export const generateForm=payload=>{
  return{
    type:FORM,
    payload
  }
}
export const removeItem=payload=>{
  return{
    type:FORM,
    payload
  }
}
export const setFormItem=payload=>{
  return{
    type:FORM_ITEM,
    payload
  }
}

export const saveFormItem=payload=>{
  return{
    type:SAVE_ITEM,
    payload
  }
}

export const setProduct = data=>async(dispatch) => {
    const elpathname = data
    if(elpathname == '/basic/plansummary/Relationship'){
      const data ={
      title: "Basic Plan",
      product_key: getProdKey().Relationship
    }           
    dispatch(getProduct(data))    
    }
    else if(elpathname =='/advanced/plansummary/Relationship'){
      const data ={
        title: "Advanced Plan",
        product_key: getProdKey().Relationship
      }
      dispatch(getProduct(data))
    }
    else if(elpathname =='/premium/plansummary/Relationship'){
      const data ={
        title: "Premium plan",
        product_key: getProdKey().Relationship,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname =='/initial/plansummary/Relationship'){
      const data ={
        title: "Initial Assesment",
        product_key:getProdKey().Relationship,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname == '/basic/plansummary/Stress'){
      const data ={                     
        title: "Basic Plan",   
        product_key:getProdKey().Stress,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname =='/advanced/plansummary/Stress'){
      const data = {
        title: "Advanced Plan",
        product_key: getProdKey().Stress,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname =='/premium/plansummary/Stress'){
      const data ={
        title: "Premium plan",
        product_key: getProdKey().Stress,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname =='/initial/plansummary/Stress'){
      const data ={
        title: "Initial Assesment",
        product_key: getProdKey().Stress,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname == '/basic/plansummary/Depression'){
      const data ={                  
        title: "Basic Plan",
        product_key: getProdKey().Depression,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname =='/advanced/plansummary/Depression'){
      const data ={  
        title: "Advanced Plan",
        product_key: getProdKey().Depression,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname =='/premium/plansummary/Depression'){
      const data ={  
        title: "Premium plan",
        product_key: getProdKey().Depression,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname =='/initial/plansummary/Depression'){
      const data ={     
        title: "Initial Assesment",
        product_key: getProdKey().Depression,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname == '/basic/plansummary/Chat'){
      const data ={                       
        title: "Basic Plan",
        product_key: getProdKey().Chat,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname =='/advanced/plansummary/Chat'){
      const data ={   
        title: "Advanced Plan",
        product_key: getProdKey().Chat,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname =='/premium/plansummary/Chat'){
      const data ={                
        title: "Premium plan",
        product_key: getProdKey().Chat,
      }
      dispatch(getProduct(data))
    }
    else if(elpathname =='/initial/plansummary/Chat'){
      const data ={              
        title: "Initial Assesment",
        product_key: getProdKey().Chat,
      }
      dispatch(getProduct(data))
    }
    else {
      const data ={              
        title: "Initial Assesment",
        product_key: getProdKey().Initial,
      }
      dispatch(getProduct(data))
    }
  }
export const getPlanDetails =data=>dispatch=>{
    const sendData = {
        url:"/getapp_product/",
        values:data
    }
   const res = PostApi(sendData)
   return res  
}
export const createForms =data=>dispatch=>{
  const sendData = {
      url:"/orderform_details/",
      values:data
  }
 const res = PostApi(sendData)
 return res  
}
export const setPrice =data=>dispatch=>{
    dispatch(setPriceDetails(data))
}
export const sendOtp = data =>dispatch=> {
  const sendData = {
        url:"/sendotp/",
        values:data
    }
   const res = PostApi(sendData)
   return res
}

export const updateWebUser = data =>dispatch=>{
   const sendData={
        url:"/web_user_post/",
        values:data
    }
  const res = PostApi(sendData)
  return res
}

export const registerPatitent = (data)=>async(dispatch)=>{
    const sendData ={
        url:"/register_patient/",
        values:data
    }
   const reg = await PostApi(sendData)
   if(reg){
       dispatch(getPatientKey(reg.data))
   }
   else{
       console.log('error')
   }
   return reg
}

export const updateMobile = data=>dispatch=>{
    const sendData={
        url:"/web_user_update/",
        values:data
    }
    const resp = PostApi(sendData)
    return resp
}

export const initiatePay=data=>async(dispatch)=>{
    const sendData={
        url:"/appflow_status/",
        values:data
    }
    const resp = await PostApi(sendData)
    if(resp){
        dispatch(getAppdata(resp.data))
    }
    return resp
}

export const startPay=data=>async(dispatch)=>{
    const sendData={
        url:"/create_paymentlink/",
        values:data
    }
    const resp = await PostApi(sendData)
    if(resp){
        dispatch(getOrderId(resp))
    }
    return resp
}

export const updatePayment=data=>async(dispatch)=>{
    const sendData={
        url:"/cashfree_payment/",
        values:data
    }
    const resp = await PostApi(sendData)
    if(resp){
        dispatch(getPaymentKey(resp.data))
    }
    return resp
}

export const getPayLink = data=>dispatch=>{
    const sendData={
        url:"/get_cashfree_link/",
        values:data
    }
    const resp = PostApi(sendData)
    return resp
}

export const getOccupations=data=>dispatch=>{
  const sendData={
    url:"/get_occupations/"
  }
  const resp = GetApi(sendData)
  return resp
}

export const patientEdit = data=>dispatch=>{
  const sendData={
      url:"/patientedit_app/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}
export const bookChat = data=>dispatch=>{
  const sendData={
      url:"/book_chattherapy/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}
export const updateChatPay = data=>dispatch=>{
  const sendData={
      url:"/cashfree_payment/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}
export const getPaymentStatus = data=>dispatch=>{
  const sendData={
      url:"/get_paymentstatus/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}
export const payFlow = data=>dispatch=>{
  const sendData={
      url:"/appflow_status/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}

export const getDoctor = data=>dispatch=>{
  const sendData={
      url:"/doctor_language/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}
export const updatePay = data=>async(dispatch)=>{
  const sendData={
      url:"/cashfree_payment/",
      values:data
  }
  const resp = await PostApi(sendData)
  if(resp){
    dispatch(getUid(resp.data.uuid_token))
  }
  return resp
}
export const getInitialProblems = data=>dispatch=>{
  const sendData={
      url:"/initial_problems/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}

export const tokenCheck = data=>dispatch=>{
  const sendData={
      url:"/uuid_tokencheck/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}
export const getMode = data=>dispatch=>{
  const sendData={
      url:"/available_mode/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}
export const getDocGender = data=>dispatch=>{
  const sendData={
      url:"/doctor_gender/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}

export const dateTokencheck = data=>dispatch=>{
  const sendData={
      url:"/uuid_tokencheck/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}
export const availableDate = data=>dispatch=>{
  const sendData={
      url:"/available_date/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}

export const availableTime = data=>dispatch=>{
  const sendData={
      url:"/language_timing/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}
export const appoinDoctor= data=>dispatch=>{
  const sendData={
      url:"/appoint_doctor/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}
export const tempInitial= data=>dispatch=>{
  const sendData={
      url:"/temp_initial/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}
export const referralCheck= data=>dispatch=>{
  const sendData={
      url:"/referral_check/",
      values:data
  }
  const resp = PostApi(sendData)
  return resp
}